import { Component, ViewChild, ElementRef, ViewEncapsulation, AfterViewInit, OnInit } from '@angular/core';
import { NavItem } from 'src/app/components/template/nav/nav.model';
import { NavService } from 'src/app/components/template/nav/nav.service';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { MatSidenav } from '@angular/material/sidenav';
@Component({
  selector: 'eM-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  animations: [
    trigger("indicatorRotate", [
      state("collapsed", style({ transform: "rotate(0deg)" })),
      state("expanded", style({ transform: "rotate(180deg)" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4,0.0,0.2,1)")
      ),
    ]),
  ],
  encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit {
  expanded: boolean = true;
  navItems: NavItem[] = [
    {
      displayName: "Dashboard",
      iconName: "dashboard",
      route: "/dashboard",
    },
    {
      displayName: "Gestão de Produtos",
      iconName: "shop_two",
      children: [
        {
          displayName: "Produtos",
          iconName: "local_mall",
          route: "/products",
        },
        {
          displayName: "Marcas",
          iconName: "branding_watermark",
          route: "/brands",
        },
        {
          displayName: "Categoria",
          iconName: "category",
          route: "/categors",
        },
        {
          displayName: "Especificações",
          iconName: "folder_special",
          route: "/specifications",
        },
      ],
    },
    {
      displayName: "Gestão de Preço",
      iconName: "price_change",
    },
    {
      displayName: "Gestão de Estoque",
      iconName: "inventory_2",
    },
  ];

  @ViewChild('sidenav') sidenav!: MatSidenav;
  isExpanded = true;
  showSubmenu: boolean = false;
  isShowing = false;
  showSubSubMenu: boolean = false;
  constructor(private navService: NavService) { }

  ngOnInit(): void {
  }
  mouseenter() {
    if (!this.isExpanded) {
      this.isExpanded = true;
    }
  }

  mouseleave() {
    if (!this.isExpanded) {
      this.isExpanded = false;
      this.expanded = false;
    }
  }
}
