import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CreateAccountComponent } from './components/account/create-account/create-account.component';
import { LoginComponent } from './components/account/login/login.component';
import { RecoveryPasswordComponent } from './components/account/recovery-password/recovery-password.component';
import { AuthGuard } from './components/account/shared/auth.guard';
import { BrandCreateComponent } from './components/brand/brand-create/brand-create.component';
import { BrandDeleteComponent } from './components/brand/brand-delete/brand-delete.component';
import { BrandUpdateComponent } from './components/brand/brand-update/brand-update.component';
import { CategoryCreateComponent } from './components/category/category-create/category-create.component';
import { ProductCreateComponent } from './components/product/product-create/product-create.component';

import { AutenticationComponent } from './views/autentication/autentication.component';
import { BrandCrudComponent } from './views/brand-crud/brand-crud.component';
import { CategoryCrudComponent } from './views/category-crud/category-crud.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { HomeComponent } from './views/home/home.component';
import { ProductCrudComponent } from './views/product-crud/product-crud.component';

const routes: Routes = [
  {
    path: 'login', component: LoginComponent
  },
  {
    path: 'create-account', component: CreateAccountComponent
  },
  {
    path: 'recovery-password', component: RecoveryPasswordComponent
  },
  {
    path: '',
    component: HomeComponent,
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      {
        path: 'dashboard',
        component: DashboardComponent
      },

      {
        path: 'products',
        component: ProductCrudComponent
      },
      {
        path: 'products/create',
        component: ProductCreateComponent
      },
      {
        path: 'brands',
        component: BrandCrudComponent
      },
      {
        path: 'brands/create',
        component: BrandCreateComponent
      },
      {
        path: 'brands/update/:id',
        component: BrandUpdateComponent
      },
      {
        path: 'brands/delete/:id',
        component: BrandDeleteComponent
      },
      {
        path: 'categors',
        component: CategoryCrudComponent
      },
      {
        path: 'category/create',
        component: CategoryCreateComponent
      }

    ]
    , canActivate: [AuthGuard]
  },
  {
    path: '',
    component: AutenticationComponent,
    children: [
      { path: '', redirectTo: 'login', pathMatch: 'full' },
      { path: 'login', component: LoginComponent },
      { path: 'create-account', component: CreateAccountComponent },
      { path: 'recovery-password', component: RecoveryPasswordComponent }
    ],

  }


];

@NgModule({
  imports: [RouterModule.forRoot(routes), RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
