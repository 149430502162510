import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Constantes } from 'src/app/app.contantes';
import { Product, ProductData } from './product.model';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(private http: HttpClient) { }

  findAll(page: number, size: number): Observable<ProductData> {
    let params = new HttpParams();
    //let brandData: Observable<BrandData> = new Observable<BrandData>();
    params = params.append('page', String(page));
    params = params.append('limit', String(size));
    //brandData =
    return this.http.get<ProductData>(Constantes.PRODUCT, { params }).pipe(
      map((productData: ProductData) => productData),
      catchError(err => throwError(err))
    )
    // return brandData;

  }


}
