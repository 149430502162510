import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HeaderService } from '../../template/header/header.service';
import { Brand } from '../brand.model';
import { BrandService } from '../brand.service';


@Component({
  selector: 'eM-brand-create',
  templateUrl: './brand-create.component.html',
  styleUrls: ['./brand-create.component.css']
})
export class BrandCreateComponent implements OnInit {


  brand: Brand = {
    name: ''
  }

  constructor(private brandService: BrandService, private router: Router, private headerService: HeaderService) {

    this.headerService.headerData = {
      title: 'Gestao de Produto -> Marca -> Novo',
      icon: 'branding_watermark',
      routeUrl: '/brands'

    }
  }

  ngOnInit(): void {
  }


  createBrand(): void {

    this.brandService.create(this.brand).subscribe(() => {
      this.brandService.showMessage('Marca Cadastrada!');
      this.router.navigate(['/brands']);
    }

    )

  }

  cancelarBrand(): void {
    this.router.navigate(['brands']);
  }
}
