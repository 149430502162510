import { Component, Injectable, OnInit } from '@angular/core';
import { CollectionViewer, SelectionChange, DataSource, SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl, NestedTreeControl } from '@angular/cdk/tree';
import { BehaviorSubject, merge, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators'
import { CategoryService } from '../category.service';
import { Category, CategoryFatherAndChildrenDto, Nodo } from '../category.model';
import { MatTreeFlatDataSource, MatTreeFlattener, MatTreeNestedDataSource } from '@angular/material/tree';
import { ControlContainer } from '@angular/forms';



let TREE_DATA: Nodo[] = [];


@Component({
  selector: 'eM-category-read',
  templateUrl: './category-read.component.html',
  styleUrls: ['./category-read.component.css'],

})
export class CategoryReadComponent implements OnInit {

  treeControl = new NestedTreeControl<Nodo>(node => node.children);
  dataSource = new MatTreeNestedDataSource<Nodo>();

  private allCategory: CategoryFatherAndChildrenDto[] = [];

  constructor(private categoryService: CategoryService) {
    this.dataSource.data = TREE_DATA;
  }

  hasChild = (_: number, node: Nodo) => !!node.children && node.children.length > 0;

  ngOnInit(): void {
    this.getCategoryTree();
    //console.log(this.allCategory);
  }




  getCategoryTree(): void {

    this.categoryService.readCategoryTree().pipe(
      //tap(),
      map((vetorNodo: Nodo[]) => {
        TREE_DATA = vetorNodo;
        console.log(TREE_DATA);
        this.dataSource.data = TREE_DATA;
      }),
    ).subscribe((category) => {

      console.log("dentro do subscribe");


    });

  }





  getOrdernacaoArvoreCategory(vetorCategoria: CategoryFatherAndChildrenDto[]): void {
    let arvore: Nodo[] = [];
    let visto: any = {};
    console.log("Funcao Principal de ordenacao");
    console.log("Tamanho vetor: " + vetorCategoria.length);
    console.log(vetorCategoria.length);

    for (let cat of vetorCategoria) {

      this.insercaoRecursiva(cat, arvore, 0, visto);
    }


    console.log("Ordenacao");
    console.log(arvore);
  }



  insercaoRecursiva(objCategoria: CategoryFatherAndChildrenDto, vetor: Nodo[], level: number, visto: any) {
    let levelNames = ["pai", "filho1", "filho2", "filho3", "filho4", "filho5", "filho6"];
    console.log("Recursiva...");
    console.log(objCategoria[levelNames[level] as keyof CategoryFatherAndChildrenDto]);
    console.log("Level: " + level);
    console.log(visto);


    if (objCategoria[levelNames[level] as keyof CategoryFatherAndChildrenDto] != null) {

      if (visto.hasOwnProperty(objCategoria[levelNames[level] as keyof CategoryFatherAndChildrenDto]) == false) {
        let novoNodo = { name: objCategoria[levelNames[level] as keyof CategoryFatherAndChildrenDto], children: [] };
        vetor.push(novoNodo);
        visto[objCategoria[levelNames[level] as keyof CategoryFatherAndChildrenDto]] = true;
        this.insercaoRecursiva(objCategoria, novoNodo.children, level + 1, visto);
      }
      else {
        let vetElem = vetor.find((el: Nodo) => {
          el.name == objCategoria[levelNames[level] as keyof CategoryFatherAndChildrenDto]
        });
        if (vetElem?.children)
          this.insercaoRecursiva(objCategoria, vetElem.children, level + 1, visto);
      }
    }
  }

}


