import { Component, HostBinding, Input, OnInit } from "@angular/core";
import { NavItem } from "./nav.model";
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { Router } from "@angular/router";
import { NavService } from "./nav.service";

@Component({
  selector: "eM-nav",
  templateUrl: "./nav.component.html",
  styleUrls: ["./nav.component.css"],
  animations: [
    trigger("indicatorRotate", [
      state("collapsed", style({ transform: "rotate(0deg)" })),
      state("expanded", style({ transform: "rotate(180deg)" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4,0.0,0.2,1)")
      ),
    ]),
  ],
})
export class NavComponent implements OnInit {

  expanded: boolean = true;
  navItems: NavItem[] = [
    {
      displayName: "Dashboard11",
      iconName: "dashboard",
      route: "/dashboad",
    },
    {
      displayName: "Gestão de Produtos",
      iconName: "shop_two",
      // children: [
      //   {
      //     displayName: "Produtos",
      //     iconName: "local_mall",
      //     route: "/product",
      //   },
      //   {
      //     displayName: "Marcas",
      //     iconName: "branding_watermark",
      //     route: "/brands",
      //   },
      //   {
      //     displayName: "Categoria",
      //     iconName: "category",
      //     route: "/categors",
      //   },
      //   {
      //     displayName: "Especificações",
      //     iconName: "folder_special",
      //     route: "/specifications",
      //   },
      // ],
    },
    {
      displayName: "Gestão de Preço",
      iconName: "price_change",
    },
    {
      displayName: "Gestão de Estoque",
      iconName: "inventory_2",
    },
  ];

  // @HostBinding("attr.aria-expanded") ariaExpanded = this.expanded;
  // @Input() item!: NavItem;
  // @Input() depth: number = 0;

  constructor(public router: Router) {
    // if (this.depth === undefined) {
    //   this.depth = 0;
    // }
  }

  ngOnInit() {
    // this.navService.currentUrl.subscribe((url: string) => {
    //   if (this.item.route && url) {
    //     // console.log(`Checking '/${this.item.route}' against '${url}'`);
    //     this.expanded = url.indexOf(`/${this.item.route}`) === 0;
    //     this.ariaExpanded = this.expanded;
    //     // console.log(`${this.item.route} is expanded: ${this.expanded}`);
    //   }
    // });
  }

  // onItemSelected(item: NavItem) {
  //   if (!item.children || !item.children.length) {
  //     this.router.navigate([item.route]);
  //     this.navService.closeNav();
  //   }
  //   if (item.children && item.children.length) {
  //     this.expanded = !this.expanded;
  //   }
  // }
}
