import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar'
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Constantes } from 'src/app/app.contantes';
import { Brand, BrandData } from './brand.model';



@Injectable({
  providedIn: 'root'
})
export class BrandService {


  constructor(
    private snackBar: MatSnackBar,
    private http: HttpClient,
  ) { }


  showMessage(msg: string, isError: boolean = false): void {
    this.snackBar.open(msg, 'X', {
      duration: 3000,
      horizontalPosition: "center",
      verticalPosition: "bottom",
      panelClass: isError ? ['msg-error'] : ['msg-success']
    }
    )
  }
  create(brand: Brand): Observable<Brand> {
    return this.http.post<Brand>(Constantes.BRAND, brand).pipe(
      map((obj => obj),
        catchError((e) => this.errorHandler(e)))
    );
  }

  errorHandler(e: any): Observable<any> {
    this.showMessage("Ocorreu um erro!", true);
    return EMPTY;
  }

  read(): Observable<Brand[]> {
    return this.http.get<Brand[]>(Constantes.BRAND)
  }


  readById(id: string): Observable<Brand> {
    return this.http.get<Brand>(Constantes.BRAND + '/' + `${id}`).pipe(
      map((obj => obj),
        catchError((e) => this.errorHandler(e)))
    );
  }

  update(brand: Brand): Observable<Brand> {
    return this.http.put<Brand>(Constantes.BRAND + '/' + `${brand.id}`, brand).pipe(
      map((obj => obj),
        catchError((e) => this.errorHandler(e)))
    );
  }

  delete(id: string): Observable<Brand> {
    return this.http.delete<Brand>(Constantes.BRAND + '/' + `${id}`).pipe(
      map((obj => obj),
        catchError((e) => this.errorHandler(e)))
    );
  }

  findAll(page: number, size: number): Observable<BrandData> {
    let params = new HttpParams();
    //let brandData: Observable<BrandData> = new Observable<BrandData>();
    params = params.append('page', String(page));
    params = params.append('limit', String(size));
    //brandData =
    return this.http.get<BrandData>(Constantes.BRANDPAGINATION, { params }).pipe(
      map((brandData: BrandData) => brandData),
      catchError(err => throwError(err))
    )
    // return brandData;

  }

  findAllLike(texto: string, page: number, size: number): Observable<BrandData> {
   
    let params = new HttpParams();
    //let brandData: Observable<BrandData> = new Observable<BrandData>();
    params = params.append('page', String(page));
    params = params.append('limit', String(size));
    //brandData =
    return this.http.get<BrandData>(Constantes.BRANDPAGINATION + `/${texto}`, { params }).pipe(
      map((brandData: BrandData) => brandData),
      tap((x)=> {console.log(x.data)}),
      catchError(err => throwError(err))
    )
    // return brandData;

  }

}
