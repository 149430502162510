import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderService } from '../../template/header/header.service';
import { Brand } from '../brand.model';
import { BrandService } from '../brand.service';

@Component({
  selector: 'eM-brand-delete',
  templateUrl: './brand-delete.component.html',
  styleUrls: ['./brand-delete.component.css']
})
export class BrandDeleteComponent implements OnInit {


  private brand!: Brand;


  constructor(private brandService: BrandService, private router: Router, private headerService: HeaderService, private route: ActivatedRoute) {

    this.headerService.headerData = {
      title: 'Gestao de Produto -> Marca -> Deletar',
      icon: 'branding_watermark',
      routeUrl: '/brands'

    }
  }
  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id');
    console.log(id);
    this.brandService.readById(String(id)).subscribe(brand => {
      this.brand = brand;
    });
  }

  deleteBrand(): void {

  }

  cancelBrand(): void {
    this.router.navigate(['brands']);
  }

}
