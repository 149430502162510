import { Component, OnInit } from '@angular/core';
import { MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Brand, BrandData } from '../brand.model';
import { BrandService } from '../brand.service';



@Component({
  selector: 'eM-brand-read',
  templateUrl: './brand-read.component.html',
  styleUrls: ['./brand-read.component.css']
})
export class BrandReadComponent implements OnInit {

  brands: Brand[] = [];
  pageEvent: PageEvent = new PageEvent();
  // dataSource: any = null;
  dataSource: BrandData = {
    data: [],
    limit: 0,
    page: 0,
    totalCount: 0
  }
  displayedColumns = ['id', 'nome', 'actions'];
  constructor(private brandService: BrandService) { }


  ngOnInit(): void {
    // this.brandService.read().subscribe(brands => {
    //   this.brands = brands;
    //   console.log(brands);
    // })
    this.initDataSource();
  }

  initDataSource() {
    this.brandService.findAll(1, 9).pipe(
      //tap(brands => console.log(brands)),

      map((brandData: BrandData) => {
        this.brands = brandData.data;
        this.dataSource = brandData
      }),
      //tap(() => console.log("Datasource: " + JSON.stringify(this.dataSource)))

    ).subscribe();

  }

  onPaginateChange(event: PageEvent) {
    let page = event.pageIndex;
    let size = event.pageSize;
    page = page + 1;
    //console.log("Page " + page)
    this.brandService.findAll(page, size).pipe(
      map((brandData: BrandData) => this.brands = brandData.data)
    ).subscribe();
  }

  applyFilter(event: any) {
    const filterValue = (event.target as HTMLInputElement).value
    if (filterValue.length >= 3) {
      //console.log("event: ", filterValue);
      const brands = this.brandService.findAllLike(filterValue, 1, 10).pipe(
        //tap(brands => console.log(brands)),

        map((brandData: BrandData) => {
          this.brands = brandData.data;
          this.dataSource = brandData
        }),
        //tap(() => console.log("Datasource: " + JSON.stringify(this.dataSource)))

      ).subscribe();
    } else if (filterValue.length == 0) {
      this.initDataSource();
    }
  }


}
