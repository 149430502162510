import { Component } from '@angular/core';
import { map } from 'rxjs/operators';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { DashboardService } from '../dashboard.service';
import { Dashboard } from '../dashboard.model';

@Component({
  selector: 'eM-dashboard-produto',
  templateUrl: './dashboard-produto.component.html',
  styleUrls: ['./dashboard-produto.component.css']
})
export class DashboardProdutoComponent {
  /** Based on the screen size, switch from standard to one column per row */
  /** Based on the screen size, switch from standard to one column per row */
  dadosEqi: Dashboard[] = [];
  dadosSkuStatus: Dashboard[] = [];

  view: any[] = [1000, 300];
  viewSkuStatus: any[] = [1000, 300];

  cards = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map(({ matches }) => {
      if (matches) {
        return [
          { title: 'Card 1', cols: 1, rows: 1 },
          { title: 'Card 2', cols: 1, rows: 1 },
          { title: 'Card 3', cols: 1, rows: 1 },
          { title: 'Card 4', cols: 1, rows: 1 }
        ];
      }

      return [
        { title: 'Card 1', cols: 2, rows: 1 },
        { title: 'Card 2', cols: 1, rows: 1 },
        { title: 'Card 3', cols: 1, rows: 2 },
        { title: 'Card 4', cols: 1, rows: 1 }
      ];
    })
  );
  // options
  showLegend: boolean = true;
  showLabels: boolean = true;
  cardColor: string = '#232837';
  colorScheme = {
    domain: ['#CFC0BB', '#E44D25', '#FFFF00', '#39FF14']
  };

  colorSchemeSkuStatus = {
    domain: ['#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5']
  };
  constructor(private breakpointObserver: BreakpointObserver, private dashboardService: DashboardService) {

  }
  ngOnInit(): void {
    this.dashboardService.readEqi().subscribe(eqi => {
      this.dadosEqi = eqi;
      Object.assign(this, this.dadosEqi);
      console.log(eqi);
    });

    this.dashboardService.readSKUStatus().subscribe(skuStatus => {
      this.dadosSkuStatus = skuStatus;
      Object.assign(this, this.dadosSkuStatus);
      console.log(skuStatus);
    });

  }
  onSelect(event: any) {
    console.log(event);
  }
}
