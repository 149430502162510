import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from '../shared/account.service';

@Component({
  selector: 'eM-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  hide = true;
  login = {
    Email: '',
    Password: '',
  };

  constructor(
    private accountService: AccountService,
    private router: Router,
    private route: ActivatedRoute,
    private snackbar: MatSnackBar) { }

  ngOnInit(): void {
  }


  async onSubmit() {
    try {
      const result = await this.accountService.login(this.login);
      console.log(`Login efetuado: ${result}`);
      this.router.navigateByUrl('/');
      let infoUser = this.accountService.infoUserLoggedIn();
      console.log(infoUser.id);

    } catch (error) {
      this.snackbar.open(
        "Ops, Algo não esta correto. Por favor, verifique seu email e senha.",
        "X",
        {
          duration: 10000,
        }
      );
      console.error(error);
    }
  }

}
