import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EMPTY, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Constantes } from 'src/app/app.contantes';
import { Category, CategoryFatherAndChildrenDto, CategoryFatherNivelDto, Nodo } from './category.model';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(
    private snackBar: MatSnackBar,
    private http: HttpClient) { }


  showMessage(msg: string, isError: boolean = false): void {
    this.snackBar.open(msg, 'X', {
      duration: 3000,
      horizontalPosition: "center",
      verticalPosition: "bottom",
      panelClass: isError ? ['msg-error'] : ['msg-success']
    }
    )
  }


  create(category: Category): Observable<Category> {
    return this.http.post<Category>(Constantes.CATEGORY, category).pipe(
      map((obj => obj),
        catchError((e) => this.errorHandler(e)))
    );
  }

  errorHandler(e: any): Observable<any> {
    this.showMessage("Ocorreu um erro!", true);
    return EMPTY;
  }

  read(): Observable<Category[]> {
    return this.http.get<Category[]>(Constantes.CATEGORY)
  }



  readCategoryTree(): Observable<Nodo[]> {
    return this.http.get<Nodo[]>(Constantes.CATEGORYTREE)
  }

  readNivelFatherById(id: number): Observable<CategoryFatherNivelDto[]> {
    return this.http.get<CategoryFatherNivelDto[]>(Constantes.CATEGORYFATHER + '/'+id)
  }


  readFather(): Observable<Category[]> {
    return this.http.get<Category[]>(Constantes.CATEGORYFATHER)
  }

  readChildren(id: number): Observable<Category[]> {
    return this.http.get<Category[]>(Constantes.CATEGORYCHILDREN + '/' + `${id}`).pipe(
      map((obj => obj),
        catchError((e) => this.errorHandler(e)))
    );
  }


  readById(id: string): Observable<Category> {
    return this.http.get<Category>(Constantes.CATEGORY + '/' + `${id}`).pipe(
      map((obj => obj),
        catchError((e) => this.errorHandler(e)))
    );
  }

  update(category: Category): Observable<Category> {
    return this.http.put<Category>(Constantes.BRAND + '/' + `${category.id}`, category).pipe(
      map((obj => obj),
        catchError((e) => this.errorHandler(e)))
    );
  }

  delete(id: string): Observable<Category> {
    return this.http.delete<Category>(Constantes.CATEGORY + '/' + `${id}`).pipe(
      map((obj => obj),
        catchError((e) => this.errorHandler(e)))
    );
  }




}
