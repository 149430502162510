import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Constantes } from 'src/app/app.contantes';
import { Dashboard } from './dashboard.model';

@Injectable()
export class DashboardService {

  constructor(private http: HttpClient) {

  }

  readEqi(): Observable<Dashboard[]> {
    return this.http.get<Dashboard[]>(Constantes.DASHBOARDEQI)
  }

  readSKUStatus(): Observable<Dashboard[]> {
    return this.http.get<Dashboard[]>(Constantes.DASHBOARDSKUSTATUS)
  }
}
