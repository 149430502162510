import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'eM-autentication',
  templateUrl: './autentication.component.html',
  styleUrls: ['./autentication.component.css']
})
export class AutenticationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
