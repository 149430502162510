import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Constantes } from 'src/app/app.contantes';
import jwt_decode from "jwt-decode";
import { LoginDto } from '../login/login.model';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(private snackBar: MatSnackBar,
    private http: HttpClient) { }

  async login(user: any) {
    const result = await this.http.post<any>(Constantes.LOGIN, user).toPromise();
    if (result && result.access_token) {
      window.localStorage.setItem('token', result.access_token);
      return true;
    }
    return false;
  }

  createAccount(account: any) {
    return new Promise((resolve, reject) => {
      resolve(true);
    })
  }

  getAuthorizationToken() {
    const token = window.localStorage.getItem('token');
    return token;
  }

  getTokenExperationDate(token: string): Date {
    const decoded: any = jwt_decode(token);

    if (decoded.exp === undefined) {
      throw Error('Token Invalido')
    }

    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);
    return date;


  }

  isTokenExpired(token?: string): boolean {
    if (!token) {
      return true;
    }

    const date = this.getTokenExperationDate(token);
    if (date === undefined) {
      return false;
    }
    return !(date.valueOf() > new Date().valueOf());

  }

  infoUserLoggedIn(): LoginDto {
    const token = this.getAuthorizationToken();
    const decoded: any = jwt_decode(token!);
    const info: LoginDto = {
      id: decoded.sub,
      login: decoded.login,
      nivel: decoded.acc
    }

    return info;
  }

  isUserLoggedIn() {
    const token = this.getAuthorizationToken();
    if (!token) {
      return false;
    } else if (this.isTokenExpired(token)) {
      return false;
    }
    return true;
  }
}
