import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'eM-recovery-password',
  templateUrl: './recovery-password.component.html',
  styleUrls: ['./recovery-password.component.css']
})
export class RecoveryPasswordComponent implements OnInit {

  recovery = {
    Email: ''
  }

  constructor() { }

  ngOnInit(): void {
  }

}
