export class Constantes {

  public static BASEURL = 'http://api.mark2.emercado.tech/api/v1';
  public static LOGIN = 'http://api.pxm.emercado.tech/api/v1/auth/login';
  public static BRAND = Constantes.BASEURL + "/brand";
  public static BRANDPAGINATION = Constantes.BASEURL + "/brand/withPages";
  public static PRODUCT = Constantes.BASEURL + "/product";
  public static CATEGORY = Constantes.BASEURL + "/category";
  public static CATEGORYFATHER = Constantes.BASEURL + "/category/father";
  public static CATEGORYCHILDREN = Constantes.BASEURL + "/category/children";
  public static CATEGORYTREE = Constantes.BASEURL + "/category/children/tree";
  public static SKU = Constantes.BASEURL + "/sku";
  public static FEATURE = Constantes.BASEURL + "/feature";
  public static DASHBOARDEQI = Constantes.BASEURL + "/dashboard/eqi"
  public static DASHBOARDSKUSTATUS = Constantes.BASEURL + "/dashboard/sku-status"



}
