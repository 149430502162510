import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AccountService } from '../shared/account.service';

@Component({
  selector: 'eM-create-account',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.css']
})
export class CreateAccountComponent implements OnInit {

  user = {
    Nome: '',
    Empresa: '',
    Email: '',
    Senha: ''
  }
  constructor(private accountService: AccountService,
    private router: Router,
    private snackbar: MatSnackBar) { }

  ngOnInit(): void {
  }

  async onSubmit() {
    try {
      const result = await this.accountService.createAccount(this.user);
      console.log(`Cadastro efetuado: ${result}`);
      this.router.navigate(['/login']);

    } catch (error) {
      this.snackbar.open(
        "Ops, Algo não esta correto. Por favor, verifique os campos.",
        "X",
        {
          duration: 10000,
        }
      );
      console.error(error);
    }
  }

}
