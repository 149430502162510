import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderService } from '../../template/header/header.service';
import { Brand } from '../brand.model';
import { BrandService } from '../brand.service';

@Component({
  selector: 'eM-brand-update',
  templateUrl: './brand-update.component.html',
  styleUrls: ['./brand-update.component.css']
})
export class BrandUpdateComponent implements OnInit {

  //brand: Brand;
  //brand: Brand;

  private brand!: Brand;

  constructor(private brandService: BrandService, private router: Router, private headerService: HeaderService, private route: ActivatedRoute) {

    this.headerService.headerData = {
      title: 'Gestao de Produto -> Marca -> Update',
      icon: 'branding_watermark',
      routeUrl: '/brands'

    }
  }

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id');
    this.brandService.readById(String(id)).subscribe(brand => {
      this.brand = brand;
    });
  }

  updateBrand(): void {
    this.brandService.update(this.brand).subscribe(() => {
      this.brandService.showMessage('Marca Atualizada com sucesso');
      this.router.navigate(['brands']);
    })
  }

  cancelBrand(): void {
    this.router.navigate(['brands']);
  }

}
