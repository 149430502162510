import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AccountService } from '../components/account/shared/account.service';




@Injectable()
export class AuthInteceptor implements HttpInterceptor {


  constructor(private accountService: AccountService) {

  }


  intercept(req: HttpRequest<any>, next: HttpHandler) {

    const token = this.accountService.getAuthorizationToken();
    let request: HttpRequest<any> = req;

    if (token && !this.accountService.isTokenExpired(token)) {
      //O requeste eh imutavel , ou seja, nao eh
      // Faco o clone para conseguir mudar as propriedades
      // passo o token de autenticacao

      request = req.clone({ headers: req.headers.set('Autorization', `Bearer ${token}`) });
    }

    return next.handle(request).pipe(
      catchError(this.handleError)
    );
  }


  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      //Erro de client -side ou de rede
      console.error('Ocorreu um erro:', error.error.message);
    } else {
      console.error(
        `Codifo de erro ${error.status}, ` + `Erro: ${JSON.stringify(error.error)}`
      );
    }
    return throwError('Ocorreu um erro, tente novamente');
  }


}
