import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { HeaderService } from '../../template/header/header.service';
import { Category, CategoryFatherNivelDto } from '../category.model';
import { CategoryService } from '../category.service';

interface Nodo {
  name: string;
  children: Nodo[];
}

let TREE_DATA: Nodo[] = [

];

@Component({
  selector: 'eM-category-create',
  templateUrl: './category-create.component.html',
  styleUrls: ['./category-create.component.css']
})
export class CategoryCreateComponent implements OnInit {

  selectCatPaiValue: number = 0;
  treeControl = new NestedTreeControl<Nodo>(node => node.children);
  dataSource = new MatTreeNestedDataSource<Nodo>();

  qtdMinControl = new FormControl(50, Validators.min(2));
  formulario: FormGroup;
  hideRequiredControl = new FormControl(false);
  floatLabelControl = new FormControl('auto');
  allCategory: Category[] = [];
  nivelCategoryFather: CategoryFatherNivelDto[] = [];
  category: Category = {
    name: '',
    minStock: 0,
    fatherCategoryId: 0,
    createdBy: 0,
  }

  constructor(private fb: FormBuilder, private snackbar: MatSnackBar, private router: Router, private categoryService: CategoryService, private headerService: HeaderService) {

    this.headerService.headerData = {
      title: 'Gestao de Produto -> Categoria -> Nova',
      icon: 'branding_watermark',
      routeUrl: '/categors'

    };

    this.formulario = this.fb.group({
      fontSize: this.qtdMinControl,
      hideRequired: this.hideRequiredControl,
      floatLabel: this.floatLabelControl
      // nome: [null,[Validators.required, Validators.min(3)]],
      // storeMin: [null],
      // catFather: [null]

    });
    this.dataSource.data = TREE_DATA;
  }

  hasChild = (_: number, node: Nodo) => !!node.children && node.children.length > 0;

  getFontSize() {
    return Math.max(10, this.qtdMinControl.value);
  }

  ngOnInit(): void {



    this.getAllCategorys();
  }

  getAllCategorys(): void {
    this.categoryService.read().pipe(
      //tap(),
      map((category: Category[]) => {
        this.allCategory = category;
        console.log(this.allCategory);
      }),
    ).subscribe();
  }


  getCategoryNivelFather(id: number): void {

    console.log("ID PAI: " + id);

    this.categoryService.readNivelFatherById(id).pipe(
      //tap(),
      map((catVetor: CategoryFatherNivelDto[]) => {

        let vetorNo: Nodo[] = [];
        let aux: Nodo[] = vetorNo;
        this.nivelCategoryFather = catVetor;
        this.nivelCategoryFather.forEach(c => {
          let a: Nodo = {
            name: c.name,
            children: []
          };
          aux.push(a);
          aux = a.children;
          this.treeControl.expand(a);

        });
        this.dataSource.data = vetorNo;
        this.treeControl.isExpandable;

      }),
    ).subscribe();

  }


  saveCategory(): void {
    this.category.fatherCategoryId = this.selectCatPaiValue;
    console.log('dados');
    console.log(this.category.name);
    console.log(this.category.minStock);
    console.log(this.selectCatPaiValue);
    let qtd
    if (this.category.name == null || this.category.name == "") {
      this.snackbar.open(
        "Verificar o nome da categoria.",
        "X",
        {
          duration: 10000,
        }
      );
      return;
    }
    if (this.category.minStock <= 0) {
      this.snackbar.open(
        "Verificar o quantidade de Estoque minimo da categoria.",
        "X",
        {
          duration: 10000,
        }
      );
      return;
    }
    if (this.selectCatPaiValue <= 0) {
      this.snackbar.open(
        "Selecione a categoria principal.",
        "X",
        {
          duration: 10000,
        }
      );
      return;
    }
    this.categoryService.create(this.category).subscribe(() => {
      this.categoryService.showMessage('Category Cadastrada!');
      this.router.navigate(['/categors']);
    });

  }

  cancelCategory(): void {
    this.router.navigate(['categors']);
  }

}
