import { NavigationEnd, Router,Event } from "@angular/router";
import { BehaviorSubject } from "rxjs";

export class NavService {
  public appDrawer: any;

  constructor() {

  }


}
