import { DashboardService } from './components/dashboard/dashboard.service';
import { LOCALE_ID, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HeaderComponent } from "./components/template/header/header.component";
import { MatToolbarModule } from "@angular/material/toolbar";
import { FooterComponent } from "./components/template/footer/footer.component";
import { NavComponent } from "./components/template/nav/nav.component";
import { MatListModule } from "@angular/material/list";
import { MatCardModule } from "@angular/material/card";
import { MatButtonModule } from "@angular/material/button";
import { MatExpansionModule } from "@angular/material/expansion";
import { DashboardComponent } from "./views/dashboard/dashboard.component";
import { ProductCrudComponent } from "./views/product-crud/product-crud.component";
import { FlexLayoutModule } from "@angular/flex-layout";
import { BrandCrudComponent } from "./views/brand-crud/brand-crud.component";
import { CategoryCrudComponent } from "./views/category-crud/category-crud.component";
import { BrandCreateComponent } from "./components/brand/brand-create/brand-create.component";
import { CategoryCreateComponent } from "./components/category/category-create/category-create.component";
import { ProductCreateComponent } from "./components/product/product-create/product-create.component";
import { HttpClientModule } from "@angular/common/http";
import {
  MatFormFieldModule,
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
} from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrandReadComponent } from "./components/brand/brand-read/brand-read.component";
import { CategoryReadComponent } from "./components/category/category-read/category-read.component";
import { ProductReadComponent } from "./components/product/product-read/product-read.component";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import localePt from "@angular/common/locales/pt";
import { registerLocaleData } from "@angular/common";
import { MatTableModule } from "@angular/material/table";
import {
  MatPaginatorIntl,
  MatPaginatorModule,
} from "@angular/material/paginator";
import { MatSortModule } from "@angular/material/sort";
import { getPortuguesePaginatorIntl } from "./pt-br-paginator-intl";
import { BrandUpdateComponent } from "./components/brand/brand-update/brand-update.component";
import { BrandDeleteComponent } from "./components/brand/brand-delete/brand-delete.component";
import { MatIconModule } from "@angular/material/icon";
import { MatTreeModule } from "@angular/material/tree";
import { MatMenuModule } from "@angular/material/menu";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { CdkTreeModule } from "@angular/cdk/tree";
import { MatSelectModule } from "@angular/material/select";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { LoginComponent } from "./components/account/login/login.component";
import { CreateAccountComponent } from "./components/account/create-account/create-account.component";
import { HomeComponent } from "./views/home/home.component";
import { httpInterceptorProviders } from "./http-interceptors";
import { RecoveryPasswordComponent } from "./components/account/recovery-password/recovery-password.component";
import { MatSidenavModule } from "@angular/material/sidenav";
import { NavService } from "./components/template/nav/nav.service";
import { MatGridListModule } from '@angular/material/grid-list';
import { LayoutModule } from '@angular/cdk/layout';
import { DashboardProdutoComponent } from './components/dashboard/dashboard-produto/dashboard-produto.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';

registerLocaleData(localePt);
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    NavComponent,
    DashboardComponent,
    ProductCrudComponent,
    BrandCrudComponent,
    CategoryCrudComponent,
    BrandCreateComponent,
    CategoryCreateComponent,
    ProductCreateComponent,
    BrandReadComponent,
    CategoryReadComponent,
    ProductReadComponent,
    BrandUpdateComponent,
    BrandDeleteComponent,
    LoginComponent,
    CreateAccountComponent,
    HomeComponent,
    RecoveryPasswordComponent,
    DashboardProdutoComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatListModule,
    MatSidenavModule,
    MatCardModule,
    MatExpansionModule,
    FlexLayoutModule,
    MatButtonModule,
    HttpClientModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatSnackBarModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatIconModule,
    MatTreeModule,
    MatMenuModule,
    MatProgressBarModule,
    MatCheckboxModule,
    CdkTreeModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatGridListModule,
    LayoutModule,
    NgxChartsModule,
  ],
  providers: [
    DashboardService,
    httpInterceptorProviders,
    {
      provide: LOCALE_ID,
      useValue: "pt-BR",
    },
    {
      provide: MatPaginatorIntl,
      useValue: getPortuguesePaginatorIntl(),
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { floatLabel: "always" },
    }, NavService
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
